<script setup lang="ts">
const emit = defineEmits(["update:modelValue"]);
interface Props {
	modelValue: string[];
}

const { modelValue } = defineProps<Props>();

const isSelected = (id: string) => {
	return modelValue.includes(id);
};

const onClick = (id: string) => {
	const newModelValue = modelValue.includes(id) ? modelValue.filter((i) => i !== id) : [...modelValue, id];
	emit("update:modelValue", newModelValue);
};
</script>

<template>
	<div class="flex overflow-x-auto snap-x lg:grid lg:grid-cols-3 gap-2">
		<slot :on-click="onClick" :is-selected="isSelected" />
	</div>
</template>
